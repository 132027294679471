.custom-yellow {
  color: #f9ee20 !important;
}

.custom-pink {
  color: #ec166c;
}

.custom-green {
  color: #64c496;
}

.custom-bg-pink {
  background-color: #ec166c;
}

.custom-bg-green {
  background-color: #64c496;
}

.custom-purple {
  color: #622f8f !important;
}

.custom-bg-purple {
  background-color: #622f8f !important;
}

.custom-bg-yellow {
  background-color: #f9ee20 !important;
}

.list__item.yellow:before {
  color: #f9ee20;
}
.box-black-container {
  position: relative;
}
.box-black-container :hover .box-black {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 23;
  background-color: #ec166c;
}

.foreground-text {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.726);
  top: 0;
}
.home-header {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
}
.foreground-text h2 {
  font-size: 9em;
  font-weight: 700;
  /* left: 15%; */
  padding-left: 15%;
  line-height: 0.8;
}
.stories {
  font-size: 80px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .home-header {
    height: auto;
    width: 100%;
  }

  .foreground-text h2 {
    font-size: 4em !important;

    padding-left: 0;
    line-height: 1.2em;
  }

  .stories {
    font-size: 1em !important;
    font-weight: 700;
    /* margin-top: 20px !important; */
  }
}

.loader-container {
  background-color: #622f8f;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}
/* .image-flip{ */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.my-box {
  position: relative;
}
.my-box:hover .text-bg-container {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  display: none;
  padding: 30px;
  background-color: #19181a79;
  z-index: 200;
}

.project-name-st {
  font-weight: 600;
}
/* kjghgjjgjjg */
.card {
  /* position: absolute; */
  border-radius: 8px;
  height: 450px;
  /* width: 400px; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  /* background-image: url("/avatar/projects/5.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 80px -10px black;
  overflow: hidden;
}

.card-blur {
  /* position: absolute; */
  height: 100%;
  width: calc(100% + 1px);
  background-color: black;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
  opacity: 0.6;
}

.footer {
  z-index: 1;
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
}

svg#curve {
  position: absolute;
  fill: white;
  left: 0;
  bottom: 0;
  /* width: 400px; */
  height: 450px;
}

.connections {
  height: 80px;
  /* width: 400px; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: auto;
}

.connection {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: white;
  display: inline-block;
  padding: 5px;
  margin-right: 25px;
  transform: translateY(200px);

  transition: transform 1s cubic-bezier(0.46, 1.48, 0.18, 0.81);
}

.card:hover .connection {
  transform: translateY(0px);
}

.info {
  font-family: Inconsolata;
  padding-left: 20px;
  transform: translateY(250px);

  transition: transform 1s cubic-bezier(0.31, 1.21, 0.64, 1.02);
}

.card:hover .info {
  transform: translateY(0px);
}

.name {
  font-weight: bolder;
  padding-top: 5px;
}

.job {
  margin-top: 10px;
}

.vimeo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover-yellow:hover {
  color: #f9ee20 !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-pink:hover {
  color: #ec166c !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-green:hover {
  color: #64c496 !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-purple:hover {
  color: #622f8f !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}

.pepsi-icon {
  width: 100px !important;
  height: auto !important;
}

.silver-bird-logo {
  width: auto !important;
  height: 80px !important;
}

.heirs-logo {
  width: auto !important;
  height: 40px !important;
}

.grey-scaly img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.grey-scaly img:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

.new-img {
  height: 200px !important;
  width: auto !important;
}

.main-head-h2 {
  font-family: 'Ubuntu', serif;
  font-size: 100px;
  line-height: 120;
  font-weight: 700;
}

.userIcon {
  border-radius: '50%';
  /*padding: 10px;
  */
  background-color: #ec166c;
  height: 140px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* .form-input {
  color: #000 !important;
  border-color: #000 !important;
}

.form-input::placeholder {
  color: #000 !important;
}

.border-btn:before, .border-btn:after, .border-btn__inner:before, .border-btn__inner:after  {
  background-color: #000 !important;
} */
