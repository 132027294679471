@charset "UTF-8";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css');

.animation-area {
	/* background: linear-gradient(to left, #622f8f, #f9ee20); */
	width: 100%;
	min-height: 100vh;
	height: 100%;
}
.box-area {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.box-area li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	/* background: rgb(209, 42, 42); */
	animation: animate 20s linear infinite;
	bottom: -150px;
    font-family: FontAwesome;
  /* content: '\f22d'; */
}
.box-area li:nth-child(1) {
	left: 86%;
	width: 80px;
	height: 80px;
  background: rgb(68, 10, 226);
	animation-delay: 0s;
}
.box-area li:nth-child(1)::after {
   content:'\f04b';
   color:rgb(68, 10, 226);
   font-size: 40px;
 
}
.box-area li:nth-child(2) {
	left: 12%;
	width: 30px;
	height: 30px;
	animation-delay: 1.5s;
	animation-duration: 10s;
  /* background: rgb(153, 235, 47); */
}
.box-area li:nth-child(2)::after {
   content: '\f067';
   color:rgb(68, 10, 226);
   font-size: 40px;
	 
}
.box-area li:nth-child(3) {
  content: '\f111';
	left: 70%;
	width: 100px;
	height: 100px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(3)::after {
 content: '\f10c';
   color:rgb(226, 10, 10);
   font-size: 50px;
}
.box-area li:nth-child(4) {
  /* content: '\f22d'; */
	left: 42%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 15s;
}
.box-area li:nth-child(4)::after {
 content: '\f22d';
   color:rgb(28, 226, 10);
   font-size: 50px;
}

.box-area li:nth-child(5) {
	left: 65%;
	width: 40px;
	height: 40px;
	animation-delay: 1.8s;
}
.box-area li:nth-child(5)::after {
 content: '\f005';
   color:rgb(112, 22, 196);
   font-size: 50px;
}
.box-area li:nth-child(6) {
	left: 15%;
	width: 110px;
	height: 110px;
	animation-delay: 6.5s;
}
.box-area li:nth-child(6)::after {
   content: '\f04b';
   color:rgb(10, 158, 226);
   font-size: 50px;
	 
}
.box-area li:nth-child(7) {
	left: 35%;
	width: 110px;
	height: 110px;
	animation-delay: 3.5s;
}
.box-area li:nth-child(7)::after {
   content: '\f004';
   color:rgb(231, 57, 202);
   font-size: 45px;
}
.box-area li:nth-child(8) {
	left: 72%;
	width: 110px;
	height: 110px;
	animation-delay: 3.5s;
}
.box-area li:nth-child(8)::after {
   content: '\f04b';
   color:rgb(231, 106, 57);
   font-size: 45px;
}
.box-area li:nth-child(9) {
	left: 58%;
	width: 110px;
	height: 110px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(9)::after {
   content: '\f186';
   color:rgb(32, 189, 123);
   font-size: 45px;
}
@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-800px) rotate(360deg);
		opacity: 0.1;
	}
}
.js-footer-hover-link {
	color:#fff;
}
.js-footer-hover-link:hover{
	color: #FFF200;
}