@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-yellow {
  color: #f9ee20 !important;
}

.custom-pink {
  color: #ec166c;
}

.custom-green {
  color: #64c496;
}

.custom-bg-pink {
  background-color: #ec166c;
}

.custom-bg-green {
  background-color: #64c496;
}

.custom-purple {
  color: #622f8f !important;
}

.custom-bg-purple {
  background-color: #622f8f !important;
}

.custom-bg-yellow {
  background-color: #f9ee20 !important;
}

.list__item.yellow:before {
  color: #f9ee20;
}
.box-black-container {
  position: relative;
}
.box-black-container :hover .box-black {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 23;
  background-color: #ec166c;
}

.foreground-text {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.726);
  top: 0;
}
.home-header {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
}
.foreground-text h2 {
  font-size: 9em;
  font-weight: 700;
  /* left: 15%; */
  padding-left: 15%;
  line-height: 0.8;
}
.stories {
  font-size: 80px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .home-header {
    height: auto;
    width: 100%;
  }

  .foreground-text h2 {
    font-size: 4em !important;

    padding-left: 0;
    line-height: 1.2em;
  }

  .stories {
    font-size: 1em !important;
    font-weight: 700;
    /* margin-top: 20px !important; */
  }
}

.loader-container {
  background-color: #622f8f;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}
/* .image-flip{ */
@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
.my-box {
  position: relative;
}
.my-box:hover .text-bg-container {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  display: none;
  padding: 30px;
  background-color: #19181a79;
  z-index: 200;
}

.project-name-st {
  font-weight: 600;
}
/* kjghgjjgjjg */
.card {
  /* position: absolute; */
  border-radius: 8px;
  height: 450px;
  /* width: 400px; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  /* background-image: url("/avatar/projects/5.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 80px -10px black;
  overflow: hidden;
}

.card-blur {
  /* position: absolute; */
  height: 100%;
  width: calc(100% + 1px);
  background-color: black;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
  opacity: 0.6;
}

.footer {
  z-index: 1;
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
}

svg#curve {
  position: absolute;
  fill: white;
  left: 0;
  bottom: 0;
  /* width: 400px; */
  height: 450px;
}

.connections {
  height: 80px;
  /* width: 400px; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: auto;
}

.connection {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: white;
  display: inline-block;
  padding: 5px;
  margin-right: 25px;
  transform: translateY(200px);

  transition: transform 1s cubic-bezier(0.46, 1.48, 0.18, 0.81);
}

.card:hover .connection {
  transform: translateY(0px);
}

.info {
  font-family: Inconsolata;
  padding-left: 20px;
  transform: translateY(250px);

  transition: transform 1s cubic-bezier(0.31, 1.21, 0.64, 1.02);
}

.card:hover .info {
  transform: translateY(0px);
}

.name {
  font-weight: bolder;
  padding-top: 5px;
}

.job {
  margin-top: 10px;
}

.vimeo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover-yellow:hover {
  color: #f9ee20 !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-pink:hover {
  color: #ec166c !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-green:hover {
  color: #64c496 !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
.hover-purple:hover {
  color: #622f8f !important;
  transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}

.pepsi-icon {
  width: 100px !important;
  height: auto !important;
}

.silver-bird-logo {
  width: auto !important;
  height: 80px !important;
}

.heirs-logo {
  width: auto !important;
  height: 40px !important;
}

.grey-scaly img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.grey-scaly img:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

.new-img {
  height: 200px !important;
  width: auto !important;
}

.main-head-h2 {
  font-family: 'Ubuntu', serif;
  font-size: 100px;
  line-height: 120;
  font-weight: 700;
}

.userIcon {
  border-radius: '50%';
  /*padding: 10px;
  */
  background-color: #ec166c;
  height: 140px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* .form-input {
  color: #000 !important;
  border-color: #000 !important;
}

.form-input::placeholder {
  color: #000 !important;
}

.border-btn:before, .border-btn:after, .border-btn__inner:before, .border-btn__inner:after  {
  background-color: #000 !important;
} */

@charset "UTF-8";

.animation-area {
	/* background: linear-gradient(to left, #622f8f, #f9ee20); */
	width: 100%;
	min-height: 100vh;
	height: 100%;
}
.box-area {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.box-area li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	/* background: rgb(209, 42, 42); */
	animation: animate 20s linear infinite;
	bottom: -150px;
    font-family: FontAwesome;
  /* content: '\f22d'; */
}
.box-area li:nth-child(1) {
	left: 86%;
	width: 80px;
	height: 80px;
  background: rgb(68, 10, 226);
	animation-delay: 0s;
}
.box-area li:nth-child(1)::after {
   content:'\f04b';
   color:rgb(68, 10, 226);
   font-size: 40px;
 
}
.box-area li:nth-child(2) {
	left: 12%;
	width: 30px;
	height: 30px;
	animation-delay: 1.5s;
	animation-duration: 10s;
  /* background: rgb(153, 235, 47); */
}
.box-area li:nth-child(2)::after {
   content: '\f067';
   color:rgb(68, 10, 226);
   font-size: 40px;
	 
}
.box-area li:nth-child(3) {
  content: '\f111';
	left: 70%;
	width: 100px;
	height: 100px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(3)::after {
 content: '\f10c';
   color:rgb(226, 10, 10);
   font-size: 50px;
}
.box-area li:nth-child(4) {
  /* content: '\f22d'; */
	left: 42%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 15s;
}
.box-area li:nth-child(4)::after {
 content: '\f22d';
   color:rgb(28, 226, 10);
   font-size: 50px;
}

.box-area li:nth-child(5) {
	left: 65%;
	width: 40px;
	height: 40px;
	animation-delay: 1.8s;
}
.box-area li:nth-child(5)::after {
 content: '\f005';
   color:rgb(112, 22, 196);
   font-size: 50px;
}
.box-area li:nth-child(6) {
	left: 15%;
	width: 110px;
	height: 110px;
	animation-delay: 6.5s;
}
.box-area li:nth-child(6)::after {
   content: '\f04b';
   color:rgb(10, 158, 226);
   font-size: 50px;
	 
}
.box-area li:nth-child(7) {
	left: 35%;
	width: 110px;
	height: 110px;
	animation-delay: 3.5s;
}
.box-area li:nth-child(7)::after {
   content: '\f004';
   color:rgb(231, 57, 202);
   font-size: 45px;
}
.box-area li:nth-child(8) {
	left: 72%;
	width: 110px;
	height: 110px;
	animation-delay: 3.5s;
}
.box-area li:nth-child(8)::after {
   content: '\f04b';
   color:rgb(231, 106, 57);
   font-size: 45px;
}
.box-area li:nth-child(9) {
	left: 58%;
	width: 110px;
	height: 110px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(9)::after {
   content: '\f186';
   color:rgb(32, 189, 123);
   font-size: 45px;
}
@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-800px) rotate(360deg);
		opacity: 0.1;
	}
}
.js-footer-hover-link {
	color:#fff;
}
.js-footer-hover-link:hover{
	color: #FFF200;
}
